<template>
  <div class="bag-list">
    <div class="list header">
      <div class="list-content">
        <div v-if="showCheck" class="check">
          <img v-if="checkAll" class="check-icon" :src="require('@/assets/checked-icon.png')" alt="" @click.stop="checkAllChange">
          <img v-else-if="indeterminate" class="check-icon" :src="require('@/assets/indeterminate-icon.png')" alt="" @click.stop="checkAllChange">
          <img v-else class="check-icon" :src="require('@/assets/check-icon.png')" alt="" @click.stop="checkAllChange">
          <span>全选</span>
        </div>
        <div class="name">名称</div>
        <div class="num">作业数</div>
        <div class="status">
          状态
        </div>
        <div class="clazz">学校班级</div>
        <div class="btn-column">
          操作
        </div>
      </div>
    </div>
    <div class="list" v-for="(item, index) in bagList" :key="item.id" @click="bagWorkList(index)">
      <div class="list-content">
        <div v-if="showCheck" class="check">
          <img v-if="item.checked" class="check-icon" :src="require('@/assets/checked-icon.png')" alt="" @click.stop="changeCheck(item)">
          <img v-else class="check-icon" :src="require('@/assets/check-icon.png')" alt="" @click.stop="changeCheck(item)">
          <span>选择</span>
        </div>
        <div class="name">
          <div class="name-value">{{ item.name }}</div>
          <div class="date">{{ formatTime(item.dateStart) }}</div>
        </div>
        <div class="num">{{ item.itemNum }}个子作业</div>
        <div :class="['status', item.status.toString() === '0' ? 'col-309af2' : item.status.toString() === '1' ? 'col-1DC060' : 'col-999']">
          {{ item.status.toString() === '0' ? '未开始' : item.status.toString() === '1' ? '进行中' : '已结束' }}
        </div>
        <div class="clazz">
          <div class="clazz-name">{{ item.clazzName }}</div>
          <div class="school-name">{{ item.schoolName }}</div>
        </div>
        <div class="btn-column">
          <div class="btn btn-1" @click.stop="exportExcel(item.id)">导</div>
          <div class="btn btn-2" @click.stop="setWork(item)">修</div>
          <div class="btn btn-3" @click.stop="delWork(index)">删</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs'
export default {
  name: 'BagList',
  props: {
    list: {
      type: Array,
      default: () => []
    },
    showCheck: {
      type: Boolean,
      default: () => false
    }
  },
  data() {
    return {
      bagList: [],
      selection: [],
      checkAll: false,
      indeterminate: false
    }
  },
  watch: {
    list: {
      deep:true,
      handler: function (val) {
        this.bagList = val.map(el => {
          return {
            ...el,
            checked: el.checked || false
          }
        })
        this.setSelection(false)
      }
    }
  },
  created() {
    this.bagList = this.list.map(el => {
      return {
        ...el,
        checked: el.checked || false
      }
    })
  },
  methods: {
    formatTime(time) {
      return dayjs(time).format('YYYY-MM-DD')
    },
    exportExcel(itemId) {
      this.$emit('exportExcel', itemId)
    },
    setWork(item) {
      this.$emit('setWork', item)
    },
    delWork(index) {
      this.$emit('delWork', index)
    },
    bagWorkList(index) {
      this.$emit('bagWorkList', index)
    },
    setSelection(response) {
      this.selection = this.bagList.filter(el => el.checked)
      this.indeterminate = this.selection.length && this.selection.length < this.bagList?.length
      this.checkAll = this.selection.length && this.selection.length === this.bagList?.length
      if (response) this.$emit('selectionChange', this.selection)
    },
    changeCheck(item) {
      item.checked = !item.checked
      this.setSelection(true)
    },
    checkAllChange() {
      this.checkAll = !this.checkAll
      this.bagList = this.bagList.map(el => {
        return {
          ...el,
          checked: this.checkAll
        }
      })
      this.setSelection(true)
    }
  }
}
</script>

<style lang="scss" scoped>
.bag-list{
  padding-top: 20px;
}
.list {
  padding: 0 0;
  cursor: pointer;
  transition: .5s;

  &:hover {
    box-shadow: 0 6px 20px 0 rgba(0, 0, 0, .08);
  }
  &.header{
    margin: 0 0 14px;
    &:hover {
      box-shadow: none;
    }
    .list-content{
      height: 50px;
      background: #F6F6F6;
      border-radius: 25px;
      color: #999999;
      .name{
        font-weight: initial;
      }
    }
  }

  .list-content {
    @include flex;
    height: 80px;
    border-bottom: 1px solid #F1F1F1;
    font-size: 17px;
    text-align: center;

    .check {
      flex-shrink: 0;
      width: 140px;
      color: #999999;
      display: flex;
      align-items: center;
      justify-content: center;
      .check-icon{
        width: 20px;
        height: 20px;
        margin-right: 8px;
      }
    }

    .name {
      width: 280px;
      flex: 1;
      font-weight: bold;
      text-align: left;
      padding-left: 28px;
      .name-value{
        font-weight: bold;
        font-size: 17px;
        color: #333333;
        line-height: 24px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-bottom: 6px;

      }
      .date{
        color: #999999;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
      }
    }

    .num {
      flex-shrink: 0;
      width: 150px;
      text-align: center;
    }

    .status {
      flex-shrink: 0;
      width: 100px;
      font-weight: 400;
      font-size: 17px;
      line-height: 24px;
    }

    .clazz {
      flex-shrink: 0;
      width: 190px;
      text-align: center;
      .clazz-name{
        font-weight: 400;
        font-size: 17px;
        color: #333333;
        line-height: 24px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        margin-bottom: 4px;
      }
      .school-name{
        font-weight: 400;
        font-size: 14px;
        color: #999999;
        line-height: 20px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }

    .btn-column {
      flex-shrink: 0;
      @include flex;
      width: 164px;

      .btn {
        width: 30px;
        height: 30px;
        line-height: 30px;
        border: 1px solid #1DC060;
        border-radius: 4px;
        margin-right: 8px;
        @include font(15px, #1DC060, center);
        cursor: pointer;

        &:last-child {
          margin-right: 0;
        }
      }

      .btn-1:hover {
        color: #FFFFFF;
        background-color: #1DC060;
      }

      .btn-2 {
        color: #309AF2;
        border-color: #309AF2;

        &:hover {
          color: #FFFFFF;
          background-color: #309AF2;
        }
      }

      .btn-3 {
        color: #FF3C30;
        border-color: #FF3C30;

        &:hover {
          color: #FFFFFF;
          background-color: #FF3C30;
        }
      }
    }
  }
}
</style>
